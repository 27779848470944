window.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.accordion-content').forEach(function (el) {
        el.style.display = 'none';
    });

    document.querySelectorAll('.accordion-title').forEach(function (el) {
        el.addEventListener('click', function () {
            el.classList.toggle('open');

            if (el.classList.contains('open')) {
                el.nextElementSibling.style.display = 'block';
            } else {
                el.nextElementSibling.style.display = 'none';
            }
        });
    });

    const linksColumns = document.querySelectorAll(".footer-links-column");
    const maxItemsInCollapsedState = 10;

    linksColumns.forEach((c) => {
        const listGroup = c.querySelector('.list-group');
        const links = listGroup.querySelectorAll('a');

        if (!matchMedia("(min-width: 992px)").matches || links.length <= maxItemsInCollapsedState) {
            listGroup.style['grid-template-rows'] = 'repeat(' + links.length + ', max-content)';
            return;
        }

        listGroup.style['grid-template-rows'] = 'repeat(' + maxItemsInCollapsedState + ', max-content)';
        links.forEach((item, i) => {
            if (i >= maxItemsInCollapsedState) {
                item.style.display = 'none';
            }
        });

        const btn = document.createElement('div');
        btn.innerHTML = `<button class="mb-1 expand-col-btn"><i class="icon"></i>See all ${c.getAttribute("data-title")}</button>`
        c.appendChild(btn);

        c.querySelector(".expand-col-btn")?.addEventListener('click', (e) => {
            e.target.style.display = 'none';
            listGroup.style['grid-template-rows'] = 'repeat(' + links.length + ', max-content)';
            links.forEach((item) => item.style.display = 'block');
        })
    })
});
