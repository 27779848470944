function initTrustPilotSlides() {
    new Splide('.reviews-splide', {
        perPage: 3,
        perMove: 1,
        loop: true,
        pagination: false,
        rewind: true,
        arrows: true,
        autoplay: true,
        arrowPath: "",
        interval: 10000,
        breakpoints: {
            900: {
                perPage: 1,
                perMove: 1,
                arrows: false,
                pagination: true
            },
        },
    }).mount();
}

function initMostPopularCarsSlides() {
    if (!document.querySelector('#homepage-most-popular-cars')) {
        return;
    }

    if (!matchMedia("(max-width: 768px)").matches) {
        return;
    }

    new Splide('#homepage-most-popular-cars', {
        perPage: 4,
        perMove: 1,
        loop: true,
        pagination: true,
        rewind: true,
        arrows: true,
        autoplay: false,
        arrowPath: "",
        breakpoints: {
            1440: {
                perPage: 4,
            },
            1250: {
                perPage: 3,
            },
            900: {
                perPage: 1,
                arrows: false
            },
        }
    }).mount();
}

if ('IntersectionObserver' in window) {
    const mostPopularCarsSectionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                initMostPopularCarsSlides();
                mostPopularCarsSectionObserver.disconnect();
            }
        });
    });
    mostPopularCarsSectionObserver.observe(document.querySelector('#homepage-most-popular-cars'));

    const trustPilotSectionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                initTrustPilotSlides();
                trustPilotSectionObserver.disconnect();
            }
        });
    });
    trustPilotSectionObserver.observe(document.querySelector('.block-cmc-trustpilot-reviews'));
} else {
    initMostPopularCarsSlides();
    initTrustPilotSlides();
}
